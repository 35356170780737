import React from 'react';

const Input = (props)=>{
    const {elementRef, ...newProps} = props;
    return (
        <span className="input-element">
            <input {...newProps} ref={elementRef}/>
        </span>
    );
}

export default Input;
