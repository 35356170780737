import './App.scss';
import logo from './images/logo.png';
import heart from './images/heart.png';
import {ReactComponent as Restr} from "./images/21.svg";
import Carousel from "./components/Carousel";
import { useSpring, animated as an } from 'react-spring';
import {useEffect} from "react";
import Form from "./components/Form";
import {googleTag} from "./utils/analytics";

function App() {

    useEffect(()=>{
        let width = window.outerWidth;
        if(width < 1000) {
            let wi = 400;
            let scale = width/wi;
            document.getElementById('viewport').setAttribute("content", "width="+wi+", initial-scale="+scale+", maximum-scale="+scale+", shrink-to-fit=no, user-scalable=no");
        }

        googleTag();
    }, [])

    const style1 = useSpring({ from: { opacity: 0 }, to: { opacity: 1 }, config: { duration: 1500 } });
    const style2 = useSpring({ from: { opacity: 0 }, to: { opacity: 1 }, config: { duration: 2000 } });
    const style3 = useSpring({ from: { opacity: 0 }, to: { opacity: 1 }, config: { duration: 2500 } });
    const style4 = useSpring({ from: { opacity: 0 }, to: { opacity: 1 }, config: { duration: 3000 } });

    return (
        <div className="App">
            <div className="content flex">
                <an.div style={style1} className="logo">
                    <img src={logo} alt="" />
                </an.div>

                <div className="flex s-content">
                    <div className="left">
                        <div className="form-title flex f-column">
                            <an.span style={style2} className="first">Sign up for your interest in next </an.span>
                            <an.span style={style1} className="second">generation adult service provider</an.span>
                            <an.span style={style2} className="three">in your area!</an.span>
                        </div>

                        <Form />

                        <an.h1 style={style3}>C<img src={heart} className="heart-icon" />ming soon!</an.h1>

                    </div>
                    <div className="right flex">
                        <an.div style={style2} className="images">
                            <Carousel />
                        </an.div>
                        <an.div style={style4} className="restriction">
                            <Restr />
                        </an.div>
                        <an.div style={style4} className="restriction">
                            <div className="text">+21</div>
                        </an.div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
