export const googleTag = ()=>{
    let s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://www.googletagmanager.com/gtag/js?id=UA-215518350-1';
    s.async = true;
    document.body.appendChild(s);

    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-X1EYK24M7N');
}
