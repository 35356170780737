import React, {useRef, useState} from 'react';
import Input from "./Input";
import {ReactComponent as Subscr} from "../images/desktop/subscribe.svg";
import { useSpring, animated as an } from 'react-spring';
import {post} from "../utils/request";

const Form = (props)=>{
    const style3 = useSpring({ from: { opacity: 0 }, to: { opacity: 1 }, config: { duration: 2500 } });
    const email = useRef();
    const firstname = useRef();

    const [isSubmit, setIsSubmit] = useState(false);

    const submit = (e)=>{
        e.preventDefault();

        post(
            '/user/subscribe',
            {
                firstname: firstname.current.value,
                email: email.current.value,
            },
            (response) => {
                setIsSubmit(true);
            }
        )

    }

    return (
        <an.form style={style3} className="form" onSubmit={submit}>
            {!isSubmit ?
                <>
                    <div className="form-group after-line">
                        <label className="form-label">Name</label>
                        <Input elementRef={firstname} name="firstname" type="text" className="form-control" required/>
                    </div>
                    <div className="form-group after-line">
                        <label className="form-label">E-mail</label>
                        <Input elementRef={email} name="email" type="email" className="form-control" required/>
                    </div>
                    <div className="button-group">
                    {/*<button className="btn" type="submit" ><Subscr /></button>*/}
                    <button className="btn" type="submit">
                        <span className="text">Subscribe</span>
                    </button>
                    </div>
                </>
                :
                <div className="success-form form-title">
                    <h2>You have successfully subscribed!</h2>
                </div>
            }
        </an.form>
    );
}

export default Form;
