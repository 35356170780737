const getHeaders = ()=>{
    let headers = {
        'Web-app': true,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
    //
    // if(user.isAuth()){
    //     headers['Authorization'] = 'Bearer ' + user.bearer;
    // }

    return headers;
}

const baseUrl = process.env.NODE_ENV === 'development'? process.env.REACT_APP_API_URL : process.env.REACT_APP_PROD_API_URL;

export function post(url, data, callback, errorCallback)
{
    fetch(baseUrl + url,{
        method: 'POST',
        headers: getHeaders(),
        credentials: "include",
        mode: 'cors',
        body: JSON.stringify(data)
    }).then(res => res.json())
    .then(
        (result) => {
            if(result.message === 'Not authorized' || result.code === 'Not authorized'){
                // user.logout();
                return;
            }else if(result.message){
                // alertStore.setState({
                //     success: result.message
                // });
            }
            if(callback){
                callback(result);
            }
        },
        (error) => {
            if(errorCallback){
                errorCallback(error);
            }
            if(error.message && !errorCallback){
                // alertStore.setState({danger: error.message});
            }
        }
    );
}

export function get(url, callback, errorCallback, ignoreMessage)
{
    fetch(baseUrl + url,{
        headers: getHeaders(),
        credentials: "include",
        mode: 'cors',
    }).then(res => res.json())
    .then(
        (result) => {
            if(result.message === 'Not authorized'){
                // user.logout();
                return;
            }else if(result.message && !ignoreMessage){
                // alertStore.setState({danger: result.message});
            }
            if(callback){
                callback(result);
            }
        },
        (error) => {
            if(errorCallback){
                errorCallback(error);
            }
            if(error.message){
                // alertStore.setState({danger: error.message});
            }
        }
    );
}

export function getHtml(url, callback, errorCallback)
{
    let headers = {}
    // if(user.isAuth()){
    //     headers['Authorization'] = 'Bearer ' + user.bearer;
    // }

    fetch(baseUrl + url,{
        headers: headers
    }).then(res => res.text())
        .then(
            (result) => {
                if(callback){
                    callback(result);
                }
            },
            (error) => {
                if(errorCallback){
                    errorCallback(error);
                }
            }
        );
}
