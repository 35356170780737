import React, {useEffect, useRef, useState} from 'react';
import './carousel.scss';
import girl1 from '../images/1girl.png';
import girl2 from '../images/2girl.png';
import girl3 from '../images/3girl.png';
import girl4 from '../images/4girl.png';
import girl5 from '../images/5girl.png';
import alisa from '../images/alisa.png';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const images = [
    girl1,
    girl2,
    girl3,
    girl4,
    girl5,
    alisa
];

const Carousel = () => {
    // const slider = useRef();
    // const settings = {
    //     accessibility: false,
    //     dots: false,
    //     infinite: true,
    //     speed: 0,
    //     autoplaySpeed: 100,
    //     autoplay: true,
    //     arrows: false,
    //     pauseOnFocus: false,
    //     pauseOnHover: false,
    //     beforeChange: (current, next) => {
    //         if(next === 5){
    //             slider.current.slickPause();
    //             setTimeout(()=>{
    //                 slider.current.slickPlay();
    //             }, 1000);
    //         }
    //     }
    // };

    return (
        <div className="carousel">
            <img src={alisa} />
            {/*<Slider {...settings} ref={slider}>*/}
            {/*    {images.map((item, index) =>*/}
            {/*        <div className="carousel-item" >*/}
            {/*            <img src={item} />*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</Slider>*/}
        </div>
    );
};

export default Carousel;